import { Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import map from 'lodash/map'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

import Adsense from 'components/Adsense'
import Footer from 'components/Footer'
import './style.scss'

const PostIndex = ({ data, options }) => {
  const {
    category,
    tags,
    description,
    title,
    path,
    date,
    image,
  } = data.frontmatter
  const { isIndex, adsense } = options
  const html = get(data, 'html')
  const isMore = isIndex && !!html.match('<!--more-->')
  const fluid = get(image, 'childImageSharp.fluid')

  return (
    <article className="single-blog">
      <div className="post-thumb">
        <a href="">{fluid ? <Img fluid={fluid} style={{}} /> : ''}</a>
      </div>
      <div className="post-content">
        <div className="entry-header text-center text-uppercase">
          {/*
            <a href="" className="post-cat">
              Travel
            </a>
          */}
          <h2>
            <Link to={path}>{title}</Link>
          </h2>
        </div>

        <div
          className="entry-content"
          dangerouslySetInnerHTML={{
            __html: isMore ? getDescription(html) : html,
          }}
        />

        {isMore
          ? Button({ path, label: 'Continue Reading', primary: true })
          : ''}

        <StaticQuery
          query={graphql`
            query PostIndexQuery {
              site {
                siteMetadata {
                  author
                  about
                  facebook_url
                  twitter_url
                  instagram_url
                }
              }
            }
          `}
          render={data => (
            <div className="post-meta">
              <ul className="pull-left list-inline author-meta">
                <li className="author">
                  By <Link to="/about/">{data.site.siteMetadata.author}</Link>
                </li>
                <li className="date"> On {date}</li>
              </ul>
            </div>
          )}
        />
      </div>
    </article>
  )
}

export default PostIndex

const getAd = (isIndex, adsense) => {
  return !isIndex ? <Adsense clientId={adsense} slotId="" format="auto" /> : ''
}

const getDescription = body => {
  body = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
  if (body.match('<!--more-->')) {
    body = body.split('<!--more-->')
    if (typeof body[0] !== 'undefined') {
      return body[0]
    }
  }
  return body
}

const Button = ({ path, label, primary }) => (
  <div className="continue-reading text-center text-uppercase">
    <Link className="readmore" to={path}>
      Continue Reading
    </Link>
  </div>
)

const Badges = ({ items, primary }) =>
  map(items, (item, i) => {
    return (
      <span
        className={`badge ${primary ? 'badge-primary' : 'badge-secondary'}`}
        key={i}
      >
        {item}
      </span>
    )
  })
