import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import map from 'lodash/map'
import Img from 'gatsby-image'

import Adsense from 'components/Adsense'
import Footer from 'components/Footer'
import SideProfile from 'components/SideProfile'
import LatestPosts from 'components/LatestPosts'
import './style.scss'

const Post = ({ data, options, prev_post, next_post }) => {
  const {
    category,
    tags,
    description,
    title,
    path,
    date,
    image,
  } = data.frontmatter
  const { isIndex, adsense } = options
  const html = get(data, 'html')
  const isMore = isIndex && !!html.match('<!--more-->')
  const fluid = get(image, 'childImageSharp.fluid')

  return (
    <div className="kotha-default-content">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <article className="single-blog">
              <div className="post-thumb">
                {fluid ? <Img fluid={fluid} style={{}} /> : ''}
              </div>
              <div className="post-content">
                <div className="entry-header text-center text-uppercase">
                  {/*
                  <a href="" className="post-cat">
                    Travel
                  </a>
                  */}
                  <h2>{title}</h2>
                </div>
                <div className="entry-content">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: isMore ? getDescription(html) : html,
                    }}
                  />
                </div>

                <StaticQuery
                  query={graphql`
                    query PostMetaQuery {
                      site {
                        siteMetadata {
                          author
                          about
                          facebook_url
                          twitter_url
                          instagram_url
                        }
                      }
                    }
                  `}
                  render={metaData => (
                    <div className="post-meta">
                      <ul className="pull-left list-inline author-meta">
                        <li className="author">
                          By{' '}
                          <Link to="/about/">
                            {metaData.site.siteMetadata.author}{' '}
                          </Link>
                        </li>
                        <li className="date"> On {date}</li>
                      </ul>
                    </div>
                  )}
                />
              </div>
            </article>

            <div className="row">
              {prev_post ? (
                <div className="col-md-6">
                  <div className="single-blog-box">
                    <Link to={prev_post.frontmatter.path}>
                      <Img
                        fluid={
                          prev_post.frontmatter.image.childImageSharp.fluid
                        }
                      />

                      <div className="overlay">
                        <div className="promo-text">
                          <p>
                            <i className=" pull-left fa fa-angle-left" />
                          </p>
                          <h5>{prev_post.frontmatter.title}</h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="col-md-6" />
              )}

              {next_post ? (
                <div className="col-md-6">
                  <div className="single-blog-box">
                    <Link to={next_post.frontmatter.path}>
                      <Img
                        fluid={
                          next_post.frontmatter.image.childImageSharp.fluid
                        }
                      />

                      <div className="overlay">
                        <div className="promo-text">
                          <p>
                            <i className="pull-right fa fa-angle-right" />
                          </p>
                          <h5>{next_post.frontmatter.title}</h5>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="col-md-6" />
              )}
            </div>
          </div>
          <div className="col-sm-4">
            <div className="kotha-sidebar">
              <SideProfile />

              <LatestPosts />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Post

const getAd = (isIndex, adsense) => {
  return !isIndex ? <Adsense clientId={adsense} slotId="" format="auto" /> : ''
}

const getDescription = body => {
  body = body.replace(/<blockquote>/g, '<blockquote class="blockquote">')
  if (body.match('<!--more-->')) {
    body = body.split('<!--more-->')
    if (typeof body[0] !== 'undefined') {
      return body[0]
    }
  }
  return body
}

const Button = ({ path, label, primary }) => (
  <Link className="readmore" to={path}>
    <span
      className={`btn btn-outline-primary btn-block ${
        primary ? 'btn-outline-primary' : 'btn-outline-secondary'
      }`}
    >
      {label}
    </span>
  </Link>
)

const Badges = ({ items, primary }) =>
  map(items, (item, i) => {
    return (
      <span
        className={`badge ${primary ? 'badge-primary' : 'badge-secondary'}`}
        key={i}
      >
        {item}
      </span>
    )
  })
